import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import 'ui/css/global.css'
import TagManager from 'react-gtm-module'
import Head from 'next/head'
import { useEffect } from 'react'
import { MastheadContextProvider } from 'ui/context/masthead'
import AccessibeScript from 'ui/components/organisms/c-accessibe-script'

declare global {
  export interface Window {
    newrelic: any
  }
}

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const gtmId = process.env.NEXT_PUBLIC_EVO_GMTD_ID
      gtmId && TagManager.initialize({ gtmId })
    }
  }, [])

  return (
    <MastheadContextProvider>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
        
      </Head>
      <Component {...pageProps} />
      <AccessibeScript />
    </MastheadContextProvider>
  )
}

export default appWithTranslation(App)
